import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../constants/constants';

@Pipe({
  name: 'compressedImageSrc'
})
export class CompressedImageSrcPipe implements PipeTransform {

  transform(src?: string): Promise<string> {
    if (src && !Constants.IS_SERVER_SIDE) {
      const testImage = new Image();
      testImage.src = src + '/w';

      return new Promise<string>((resolve, reject) => {
        testImage.onload = () => {
          resolve(src + '/w');  // Successfully loaded compressed image
        };
        testImage.onerror = () => {
          resolve(src);  // If the image fails to load, return the original src
        };
      });
    } else {
      return Promise.resolve(src ?? '');  // If there's no src, just return it
    }
  }
}
