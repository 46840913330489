import { Directive, ElementRef, HostListener } from '@angular/core';
import moment from 'moment';

@Directive({
  selector: '[appDobValidator]'
})
export class DobValidatorDirective {
  constructor(private el: ElementRef) {}
  
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(this.el.nativeElement.contains(event.target)) {
      
    } else {
      let dtDOB = new Date(this.el.nativeElement.value);
      let dtCurrent = new Date();
      let date18OldfromCurrentDate = moment().subtract(18, 'years').format('YYYY-MM-DD');
      if ((dtCurrent.getFullYear() - dtDOB.getFullYear()) < 18) {
        this.el.nativeElement.value = date18OldfromCurrentDate 
      }
      if (dtCurrent.getFullYear() - dtDOB.getFullYear() == 18) {
          if (dtCurrent.getMonth() < dtDOB.getMonth()) {
            this.el.nativeElement.value = date18OldfromCurrentDate 
          }
          if (dtCurrent.getMonth() == dtDOB.getMonth()) {
              if (dtCurrent.getDate() < dtDOB.getDate()) {
                this.el.nativeElement.value = date18OldfromCurrentDate 
              }
          }
      }
    }
  }
}
