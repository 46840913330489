<ng-container *ngIf="enrollmentHelper.isNotEnrolled(userActivity?.enrollmentStatus)">
    <button kendoButton [primary]="true" type="submit" class="k-button k-primary k-rounded-lg k-w-full" (click)="onEnrollCourse()" *ngIf="!isLoading">{{ course && course.courseType === 'videos' ? 'Buy Now' : course && course.showInterest ? 'Show interest' : 'Enroll' }}</button>
</ng-container>
<ng-container *ngIf="enrollmentHelper.isPaymentPending(userActivity?.enrollmentStatus)">
    <button kendoButton [primary]="true" type="submit" class="k-button k-primary k-rounded-lg k-w-full"*ngIf="!isLoading" [routerLink]="appRoutes.shoppingCart.cart">Pay Now</button>
</ng-container>
<ng-container *ngIf="enrollmentHelper.isEnrolled(userActivity?.enrollmentStatus)">
    <p class="body-sm font-weight-700">Subscribed</p>
</ng-container>
<button kendoButton [primary]="true" type="submit" class="k-button k-primary k-rounded-lg k-w-full" *ngIf="isLoading" [disabled]="true">{{ course && course.courseType === 'videos' ? 'Buy Now' : course && course.showInterest ? 'Show interest' : 'Enroll' }}</button>
