<div #contentDiv [innerHTML]="html" style="max-height: 250px; overflow: hidden;"></div>

<div class="end">
    <a>
        <button kendoButton fillMode="link" size="small" themeColor="primary" class="k-mt-auto k-button k-button-sm k-rounded-md k-button-link-primary k-button-link" (click)="toggleShowMore()">{{
            showMore ? 'Show Less' : 'Show More' }}
            <span class="btn-icon" data-icon-position="right">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M4 10L8 6L4 2" stroke="#0044CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        </button>
    </a>
</div>