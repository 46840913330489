import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { AuthService } from 'src/app/core/state/auth-state/auth.service';
import { AbstractSeminarActionService } from 'src/app/seminar/services/abstract-seminar-action.service';
import { EveryEngDomain } from 'src/app/shared/constants/everyeng-domains.enum';
import { EnrollmentHelperService } from 'src/app/shared/services/enrollment-helper.service';
import { GlobalObjectService } from 'src/app/shared/services/global-object.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
// import { PopupJoinComponent } from '../popups/popup-join/popup-join.component';

@Component({
  selector: 'app-seminar-enroll-button',
  templateUrl: './seminar-enroll-button.component.html',
  styleUrl: './seminar-enroll-button.component.scss'
})
export class SeminarEnrollButtonComponent implements OnInit {
  @Input('isEnrolled') isEnrolled: any;
  @Input('seminarId') seminarId: any;
  @Input('seminarPrice') seminarPrice: any;
  @Input('seminarAmount') seminarAmount?: number;
  @Input('returnUrl') returnUrl: string;
  @Input('userActivity') userActivity: any;
  @Input('seminar') seminar: any;
  // assetsRoot: any = ApplicationRoutes.assetsRoot;

  userLoggedInSubscription: Subscription;
  isUserLoggedIn: boolean;
  isLoading: boolean = false;
  appRoutes = ApplicationRoutes;
  constructor(
    private seminarActionHelperService: AbstractSeminarActionService,
    private authService: AuthService,
    private router: Router,
    private globalObject: GlobalObjectService,
    private paymentService: PaymentService,
    public enrollmentHelper: EnrollmentHelperService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.userLoggedInSubscription = this.authService.isUserLoggedIn$.subscribe(
      (isLogedIn: boolean) => {
        this.isUserLoggedIn = isLogedIn;
      }
    );
  }
  //seminar added to the like list
  onEnrollCourse() {
    if (!this.isUserLoggedIn) {
      // const dialogRef: DialogRef = this.dialogService
      // .open({
      //   content: PopupJoinComponent
      // });
      // const deleteInfo = dialogRef.content.instance as PopupJoinComponent;
      // deleteInfo.headerTitle = `Unlock Your true Engineering Potential!`
      // deleteInfo.description = `You need to enroll again after login if you want login??`
      // deleteInfo.buttonText = 'Join now'
      // deleteInfo.buttonText2 = 'Skip for now'
      // dialogRef.result.subscribe((data: any) => {
      //     if (data instanceof DialogCloseResult) {
      //     } else {
      //       if(data.isAction){
              this.router.navigate([ApplicationRoutes.auth.login], {
                queryParams: {
                  redirectUrl: this.router.url,
                  returnUrl: this.globalObject.getLocation()?.pathname,
                },
              });
      //       }
      //     }
      // });
    }else{
      if (!this.seminarPrice) {
        this.isLoading = true;
        this.seminarActionHelperService.enrollForSeminar(
          this.seminarId,
          2
        );
        setTimeout(() => {
          this.isLoading = false;
        }, 600);
      } else {
        this.isLoading = true;
        this.seminarActionHelperService.enrollForSeminar(
          this.seminarId,
          1
        );
        setTimeout(() => {
          this.isLoading = false;
        }, 600);
      }
    }
  }

  onRemoveFromCartCourse(){
    this.seminarActionHelperService.removeFromCart(
      this.seminarId,
      0
    )
  }

  onPayNowCourse(){
    if (!this.isUserLoggedIn) {
      this.isLoading = true;
      this.router.navigate([ApplicationRoutes.auth.login], {
        queryParams: {
          redirectUrl: this.paymentService.getPaymentUrl(
            this.seminarId,
            EveryEngDomain.Seminar
          ),
          returnUrl: this.returnUrl,
        },
      });
    } else {
      this.isLoading = true;
      this.paymentService.doPayment(this.seminarId, EveryEngDomain.Seminar, this.returnUrl);
    }
  }
  
  ngOnDestroy(): void {
    this.userLoggedInSubscription?.unsubscribe();
  }
}
