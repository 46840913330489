import { NgModule } from '@angular/core';

import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
// import { ListViewModule } from '@progress/kendo-angular-listview';
// import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { LayoutModule, PanelBarModule } from "@progress/kendo-angular-layout";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { IconsModule } from "@progress/kendo-angular-icons";
import { PopoverModule } from "@progress/kendo-angular-tooltip";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
// import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { MenuModule } from '@progress/kendo-angular-menu';

@NgModule({
  exports:[
    DialogModule,
    TooltipsModule,
    LayoutModule,
    ButtonsModule,
    IconsModule,
    PopoverModule,
    InputsModule,
    DropDownsModule,
    DateInputsModule,
    LabelModule,
    UploadsModule,
    WindowModule,
    ProgressBarModule,
    NavigationModule,
    IndicatorsModule,
    MenuModule,
    PanelBarModule
  ]
})
export class KendoModule { }
