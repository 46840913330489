import { Component, ElementRef, Input, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrl: './html-viewer.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class HtmlViewerComponent {
  @Input() html: string = '';
  showMore: boolean = false;

  @ViewChild('contentDiv', { static: false }) contentDiv: ElementRef;

  constructor(private renderer: Renderer2) {}
  
  toggleShowMore(): void {
    this.showMore = !this.showMore;
    
    if (this.contentDiv) {
      const div = this.contentDiv.nativeElement;
      if (this.showMore) {
        this.renderer.setStyle(div, 'max-height', 'none');
      } else {
        this.renderer.setStyle(div, 'max-height', '250px');
      }
    }
  }
}
