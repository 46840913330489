import { Injectable } from '@angular/core';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
import { AuthQuery } from 'src/app/core/state/auth-state/auth.query';
import { environment } from 'src/environments/environment';
import { EveryEngDomain } from '../constants/everyeng-domains.enum';
// import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
// import { PopupLoadingComponent } from '../components/popups/popup-loading/popup-loading.component';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, throwError } from 'rxjs';
import { ErrorMessage } from 'src/app/core/model/api-error';
import { ToastService } from 'src/app/core/services/toast.service';
// import { PopupErrorComponent } from '../components/popups/popup-error/popup-error.component';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  private observe!: {
    observe: 'body';
  };
  authToken: string;
  constructor(private authQuery: AuthQuery,
    private http: HttpClient,
    private toastService: ToastService
    // private dialogService: DialogService
  ) {
    this.authQuery.userAuthState$.subscribe((state) => {
      if (state && state.token) this.authToken = state.token;
    });
  }
  public doPayment(
    entityId: string,
    domain: EveryEngDomain,
    returnUrl?: string,
    message?: string,
    amount?: number
  ) {
    window.location.href = this.getPaymentUrl(
      entityId,
      domain,
      returnUrl,
      message,
      amount
    );
  }
  public doCartPayment() {
    window.location.href = `${environment.everyengPaymentLink}/do-payment?entityDomain=Cart&returnUrl=${window.location.href}&redirectUrl=${window.location.origin}/user/subscriptions&token=${this.authToken}`;
  }

  async bankTransferPayment(
    uploadedFilesList: any[]
  ) {
    // const dialogRefLoading: DialogRef = this.dialogService
    // .open({
    //   content: PopupLoadingComponent
    // });
    // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
    // loadingInfo.writeUp = `Please wait while we are uploading document.`
    const payload = new FormData();
    if (uploadedFilesList) {
      uploadedFilesList.map((uploadFileObj: any) => {
        payload.append(
          'Files',
          uploadFileObj.file || new Blob(),
          uploadFileObj.fullFileName
        );
      })

    }

    try {
      await this.http
        .post(
          apiOf.payment.banktransferproof,
          payload,
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Your Bank Transfer Request sent to EveryEng for approval. Once approved course will be available.'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()


            // },1000)
          }),
          catchError((error: ErrorMessage) => {
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefError: DialogRef = this.dialogService
            //   .open({
            //     content: PopupErrorComponent
            //   });
            //   const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //   ErrorInfo.headerTitle = error.title
            //   ErrorInfo.errorList = error.errors
            //   ErrorInfo.buttonText = `Go back`
            // },1000)
            error?.errors.map((err) => this.toastService.error(err));
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
  }

  public getPaymentUrl(
    entityId: string,
    domain: EveryEngDomain,
    returnUrl?: string,
    remarks?: string,
    amount?: number
  ) {
    let url = `${
      environment.everyengPaymentLink
    }/do-payment?entityId=${entityId}&entityDomain=${EveryEngDomain[
      domain
    ].toString()}`;
    if (returnUrl) {
      url += `&returnUrl=${window.location.origin}${returnUrl}`;
    } else {
      url += `&returnUrl=${window.location.href}`;
    }
    if (this.authToken) url = url + `&token=${this.authToken}`;
    if (remarks) url = url + `&message=${remarks}`;
    if (amount) url= url + `&amount=${amount}`;
    return url;
  }
}
