import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(
    values: any[],
    column: string = '',
    reverse: boolean = false
  ): any[] {
    if (column && column !== '') {
      if (reverse)
        return values
          .slice()
          .sort((a, b) => {
            return this.compare(a[column], b[column]);
          })
          .reverse();
      else
        return values.slice().sort((a, b) => {
          return this.compare(a[column], b[column]);
        });
    } else {
      return reverse ? values.slice().sort().reverse() : values.slice().sort();
    }
  }
  compare(a: any, b: any): number {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  }
}
