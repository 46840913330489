import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyModel } from '../../models/currency.model';
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencySelectComponent),
      multi: true,
    },
  ],
})
export class CurrencySelectComponent implements ControlValueAccessor {
  onChange: any = () => {};
  onTouch: any = () => {};
  val: CurrencyModel | undefined;

  currencies: CurrencyModel[] = [];

  constructor() {
    this.currencies = LookupService.getCurrencies();
  }

  set value(currencyCode: string | null) {
    if (currencyCode !== undefined && this.val?.currencyCode !== currencyCode) {
      this.val = this.currencies.find((c) => c.currencyCode === currencyCode);
      this.onChange(this.val);
      this.onTouch(this.val);
    }
  }

  get value(): string | null {
    return this.val?.currencyCode ?? null;
  }

  writeValue(value: CurrencyModel) {
    this.value =
      this.currencies.find((c) => c.currencyCode === value?.currencyCode)
        ?.currencyCode ?? '';
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
