import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../constants/constants';

@Pipe({
  name: 'dynamicImageSrc'
})
export class DynamicImageSrcPipe implements PipeTransform {

  transform(src: string): string {
    if(src && !Constants.IS_SERVER_SIDE){
      const screenWidth = window.innerWidth;
      if (screenWidth > 768) {
        // Return the image source for smaller screens
        return src + '/192x192';
      } else {
        // Return the image source for larger screens
        return src + '/130x125';
      }
    } else if(src) {
      return src + '/192x192';
    } else {
      return src;
    }
  }

}