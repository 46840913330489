import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateTitleString'
})
export class TruncateTitleStringPipe implements PipeTransform {

  transform(value: string, maxLength: number = 40): string {
    if(value){
      if (value.length <= maxLength) {
        return value;
      } else {
        return value.substr(0, maxLength) + '...';
      }
    }else{
      return value;
    }
  }
}
