.k-button-link-primary {
    color: #0044CC;
    background: transparent;
    border: none;
    font-weight: bold;
    text-decoration: underline;
    font-family: var(--evg-font-family-primary);
    font-size: 15px;
}

.end {
    display: flex;
    justify-content: end;
    width: 100%;
}