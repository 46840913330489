import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { AuthService } from 'src/app/core/state/auth-state/auth.service';
import { AbstractMentorCourseActionService } from 'src/app/mentor/services/abstract-mentor-course-action.service';
import { EveryEngDomain } from 'src/app/shared/constants/everyeng-domains.enum';
import { EnrollmentHelperService } from 'src/app/shared/services/enrollment-helper.service';
import { GlobalObjectService } from 'src/app/shared/services/global-object.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
// import { PopupJoinComponent } from '../popups/popup-join/popup-join.component';

@Component({
  selector: 'app-mentor-course-enroll-button',
  templateUrl: './mentor-course-enroll-button.component.html',
  styleUrl: './mentor-course-enroll-button.component.scss'
})
export class MentorCourseEnrollButtonComponent implements OnInit {
  @Input('isEnrolled') isEnrolled: any;
  @Input('courseId') courseId: any;
  @Input('price') price: any;
  @Input('courseAmount') courseAmount?: number;
  @Input('returnUrl') returnUrl: string;
  @Input('userActivity') userActivity: any;
  @Input('course') course: any;
  // assetsRoot: any = ApplicationRoutes.assetsRoot;

  userLoggedInSubscription: Subscription;
  isUserLoggedIn: boolean;
  isLoading: boolean = false;
  appRoutes = ApplicationRoutes;
  constructor(
    private mentorCourseActionHelperService: AbstractMentorCourseActionService,
    private authService: AuthService,
    private router: Router,
    private globalObject: GlobalObjectService,
    private paymentService: PaymentService,
    public enrollmentHelper: EnrollmentHelperService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.userLoggedInSubscription = this.authService.isUserLoggedIn$.subscribe(
      (isLogedIn: boolean) => {
        this.isUserLoggedIn = isLogedIn;
      }
    );
  }
  //course added to the like list
  onEnrollCourse() {
    if (!this.isUserLoggedIn) {
      // const dialogRef: DialogRef = this.dialogService
      // .open({
      //   content: PopupJoinComponent
      // });
      // const deleteInfo = dialogRef.content.instance as PopupJoinComponent;
      // deleteInfo.headerTitle = `Unlock Your true Engineering Potential!`
      // deleteInfo.description = `You need to enroll again after login if you want login??`
      // deleteInfo.buttonText = 'Join now'
      // deleteInfo.buttonText2 = 'Skip for now'
      // dialogRef.result.subscribe((data: any) => {
      //     if (data instanceof DialogCloseResult) {
      //     } else {
      //       if(data.isAction){
              this.router.navigate([ApplicationRoutes.auth.login], {
                queryParams: {
                  redirectUrl: this.router.url,
                  returnUrl: this.globalObject.getLocation()?.pathname,
                },
              });
      //       }
      //     }
      // });
    }else{
      if (!this.price) {
        this.isLoading = true;
        this.mentorCourseActionHelperService.enrollForCourse(
          this.courseId,
          2
        );
        setTimeout(() => {
          this.isLoading = false;
        }, 600);
      } else {
        this.isLoading = true;
        this.mentorCourseActionHelperService.enrollForCourse(
          this.courseId,
          1
        );
        setTimeout(() => {
          this.isLoading = false;
        }, 600);
      }
    }
  }

  onRemoveFromCartCourse(){
    this.mentorCourseActionHelperService.removeFromCart(
      this.courseId,
      0
    )
  }

  onPayNowCourse(){
    if (!this.isUserLoggedIn) {
      this.isLoading = true;
      this.router.navigate([ApplicationRoutes.auth.login], {
        queryParams: {
          redirectUrl: this.paymentService.getPaymentUrl(
            this.courseId,
            EveryEngDomain.Mentor
          ),
          returnUrl: this.returnUrl,
        },
      });
    } else {
      this.isLoading = true;
      this.paymentService.doPayment(this.courseId, EveryEngDomain.Mentor, this.returnUrl);
    }
  }
  
  ngOnDestroy(): void {
    this.userLoggedInSubscription?.unsubscribe();
  }
}