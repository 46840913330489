import { Injectable } from '@angular/core';
import { EnrollmentStatus } from '../constants/enrollment-status.enum';

@Injectable({ providedIn: 'root' })
export class EnrollmentHelperService {
  isEnrolled(enrollmentStatus: EnrollmentStatus | undefined): boolean {
    return enrollmentStatus == EnrollmentStatus.Enrolled;
  }
  isPaymentPending(enrollmentStatus: EnrollmentStatus | undefined): boolean {
    return enrollmentStatus == EnrollmentStatus.PaymentPending;
  }
  isNotEnrolled(enrollmentStatus: EnrollmentStatus | undefined): boolean {
    return (
      enrollmentStatus === EnrollmentStatus.NotEnrolled ||
      enrollmentStatus === undefined ||
      enrollmentStatus === null
    );
  }
}
